<style scoped>
    pre {
        overflow-x: auto;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }
</style>
<template>
	<Modal
		v-model="dialog"
		modalTitle="Pré-requisitos"
		:cardTitle="true"
		:withClose="true"
		myMaxWidth="640px"
		@input="$emit('close')"
	>
		<v-container fluid pa-0>
			<v-layout pa-0>
				<v-flex list-scroll sm12 style="height:82vh">
					<div class="content-text-block overflow font-sys">
						<div v-html="preRequisites"></div>
					</div>
				</v-flex>
			</v-layout>
		</v-container>
	</Modal>
</template>

<script type="plain/text">
import Modal from "@/components/Modal.vue";
import PersonService from "@/scripts/services/person.service.js";

export default {
	props: {
        value: Boolean,
        preRequisites: String,
        fromDash: Boolean
    },
	components: {
		Modal, 
	},
    created() {
        if (this.fromDash === null) this.fromDash = false
    },
	data() {
        return {
            personService: new PersonService(),
            dialog: false,
        };
	},
    watch: {
        value() {
            this.dialog = this.value;
        }
    },
    methods: {
        toggleDialog() {
            this.dialog = !this.dialog;
            this.$emit("input", this.dialog);
        },
	}
};
</script>
