<template>
  <div>
      <div v-for="(offer, index) in dynamicList" :key="'card-'+index" ref="cardOffers">
      <div class="pa-3 pb-4" style="border-bottom:1px solid #E8EAF2" v-if="(showDeleted ? true : !offer.isDeleted)">
          <div class="layout align-center mt-2 gap" v-if="offer.volunteering">
                  <div class="px-2">
                      <button class="f-size-20 text-primary fal" @click="offer.visible = !offer.visible" :class="offer.visible ? 'fa-minus-circle' : 'fa-plus-circle'"></button>
                  </div>
                  <div>
                      <div class="text-default f-size-11" v-text="getDate(offer.updatedAt)"></div>
                      <div class="layout align-center gap-sm">
                          <h3>{{ offer.campaignName }}</h3> - 
                          <h4 class="text-red f-size-18" v-text="offer.volunteering.entity.name"></h4>
                      </div>
                      <div style="display: flex; margin-top: 10px">
                        <p style="text-align: center; color:white; background: #02ACB4; border-radius: 12px; padding-right: 10px; padding-left: 10px; width: 115px" v-if="!offer.isDeleted">Ativo</p>
                        <p style="text-align: center; color:white; background: red; border-radius: 12px; padding-right: 10px; padding-left: 10px; width: 115px" v-else>Cancelado</p>
                    </div>
                  </div>
          </div>
          <div class="info-box" :class="{ active: offer.visible}">
              
              <h3 class="f-size-18" v-text="offer.volunteeringInformation.fullName"></h3>

              <!-- CARD START -->
              <v-card class="p-2 mt-2" style="border: 1px solid #E8EAF2">
                  <v-card-title class="flex-column gap-sm align-stretch">
                      <div class="layout justify-space-between">
                          <h4 class="f-size-16 text--primary" v-text="offer.offerName"></h4>
                          <div class="d-flex">
                              <div class="layout gap">
                                  <template v-if="offer.isPresentialOportunityAllowed">
                                    <v-tooltip top>
                                        <template v-slot:activator="{on}">
                                        <div v-on="on">
                                          <Icon
                                            name="text-orange fal fa-users icon-attribute f-size-18"
                                            v-on="on"
                                          />
                                        </div>
                                        </template>
                                        <span>Atuação presencial</span>
                                    </v-tooltip>
                                  </template>
                                  <template v-if="offer.isDistanceOportunityAllowed">
                                    <v-tooltip top>
                                        <template v-slot:activator="{on}">
                                        <div v-on="on">
                                          <Icon
                                            name="text-orange fal fa-globe-americas icon-attribute f-size-18"
                                            v-on="on"
                                          />
                                        </div>
                                        </template>
                                        <span>Atuação à distância</span>
                                    </v-tooltip>
                                  </template>
                              </div>
                          </div>
                      </div>

                      <!-- IS RECURRENT -->
                      <template v-if="offer.isRecurrent">
                          <div class="layout justify-space-between text--primary f-w-bold">
                              <div class="caption">Data de início</div>
                              <div class="caption">21/03/2023</div>
                          </div>
                          <v-divider></v-divider>
                          <div class="d-flex gap flex-direction-row row">
                              <v-sheet class="layout bg-orange br-sm align-center justify-center" style="max-width: 76px;">
                                  <i class="text-white fal fa-sync icon-attribute f-size-18"></i>
                              </v-sheet>
                              <div class="f-w-bold">
                                  <div class="text-orange f-size-16">{{ offer.isRecurrent ? offer.volunteeringRecurrentTypesInfo.Recurrent[0].MaxHoursDedication : ''}} horas semanais</div>
                                  <div class="text--primary f-size-20">Seg, Ter, Qui e Sex</div>
                                  <div class="caption">{{ offer.isRecurrent ? offer.volunteeringRecurrentTypesInfo.Recurrent[0].ConvertedInitialTime : '' + 'h às ' + offer.volunteeringRecurrentTypesInfo.Recurrent[0].ConvertedFinalTime + 'h' }}</div>
                              </div>
                          </div>
                          <v-divider></v-divider>
                          <div class="layout justify-space-between text--primary f-w-bold">
                              <div class="caption">Data de término</div>
                              <div class="caption">{{ getDate(offer.volunteeringRecurrentTypesInfo.Recurrent[0].VolunteeringDateUntil) }}</div>
                          </div>
                      <v-divider></v-divider>
                      </template>
                      <!-- END OF RECURRENT -->

                      <!-- START PONTUAL -->
                      <div class="d-flex flex-column gap-xs" v-if="offer.isPontual">
                          <template>
                              <div v-for="(pontual, indexPontual) in offer.volunteeringPontualTypesInfo.Pontual" :key="'consent'+indexPontual">
                                <div class="item-pill br-sm f-w-bold">
                                  <i class="fas fa-calendar icon-attribute text-orange"></i>
                                  <div class="grow text-orange" v-text="getDate(pontual.VolunteeringDate)"></div>
                                  <div>{{ pontual.ConvertedInitialTime }}h às {{ pontual.ConvertedFinalTime }}h</div>
                                </div>
                              </div>
                          </template>
                      </div>
                      
                      <!-- END PONTUAL -->
                      
                      <div class="layout gap-sm">
                          <i class="fal fa-map-marker-alt text-primary"></i>
                          <div class="f-size-12 text-dark" v-text="hasAddress(offer) ? offer.address.street + ' - ' + offer.address.number + ' - ' + offer.address.city + ' - ' + offer.address.postalCode : 'Endereço incompleto'"></div>
                      </div>    
                  </v-card-title>
              </v-card>
              <div class="layout gap-xs mt-3">
                  <v-btn class="orange text-orange ma-0" outline 
                    v-if="!offer.isDeleted" 
                    @click="$emit('cancelVolunteering', offer.registrationOfferId)"
                  >
                    Cancelar voluntariado
                  </v-btn>
                  <v-btn outline @click="consentTerm(true)">Termos</v-btn>
                  <v-btn outline @click="preRequisitesTerm(true, offer.preRequisites)">Pré-requisitos</v-btn>
              </div>
          </div>
          <ConsentTerms
            v-model="printTerms"
            :info="offer"
            @close="printTerms = false"
            :consentTerms="attributeOffer.consentTerm"
            :fromDash="true"
          >
          </ConsentTerms>
          <PreRequisites
            v-model="preRequisitesDialog"
            @close="preRequisitesDialog = false"
            :preRequisites="preRequisitesContent"
            :fromDash="true"
          >
          </PreRequisites>
      </div>
    </div>
  </div>
</template>

<script type="plain/text">
import ConsentTerms from "@/components/ConsentTerms.vue";
import CampaignService from "@/scripts/services/campaign.service"
import VolunteeringAttributeOfferService from "@/scripts/services/volunteeringAttributeOffer.service";
import VolunteeringService from "@/scripts/services/volunteering.service"
import PreRequisites from "@/components/PreRequisites.vue";

export default {
  components: {
    ConsentTerms,
    PreRequisites
  },
  props: {
    attributeOffer: Object,
    showDeleted: Boolean,
  },
  computed: {
    itemsList() {
      return this.dynamicList
    }
  },
  watch: {
    async attributeOffer() {
      await this.infoHandler()
    },
  },
  data: () => ({
    dynamicList: [],
    printTerms: false,
    volunteeringService: new VolunteeringService(),
    volunteeringAttributeOfferService: new VolunteeringAttributeOfferService(),
    campaignService: new CampaignService(),
    preRequisitesContent: null,
    preRequisitesDialog: false,
  }),
  async created() {
    await this.infoHandler()
  },
  methods: {
    getDate(date) {
      const dateValue = new Date(date)
      return  dateValue.getDate().toString().padStart(2,'0') + '/' + (dateValue.getMonth() + 1).toString().padStart(2,'0') + '/' + dateValue.getFullYear()
    },
    async infoHandler() {
      if (this.attributeOffer) {
        for (const offer of this.attributeOffer) {
          if (offer.isPontual && this.isJsonString(offer.volunteeringPontualTypesInfo)) {
            offer.volunteeringPontualTypesInfo = await JSON.parse(offer.volunteeringPontualTypesInfo)
          }
          else if (offer.isRecurrent && this.isJsonString(offer.volunteeringRecurrentTypesInfo)) {
            offer.volunteeringRecurrentTypesInfo = await JSON.parse(offer.volunteeringRecurrentTypesInfo)
          }

          if (offer.isRecurrent) {
            offer.campaignId = offer.volunteeringRecurrentTypesInfo.Recurrent[0].CampaignId  
            offer.campaignName = offer.volunteeringRecurrentTypesInfo.Recurrent[0].CampaignName
          }

          else if (offer.isPontual) {
            offer.campaignId = offer.volunteeringPontualTypesInfo.Pontual[0].CampaignId  
            offer.campaignName = offer.volunteeringPontualTypesInfo.Pontual[0].CampaignName
          }
        }

        this.attributeOffer.forEach(element => {
          this.dynamicList.push({...element, visible: false});
        });
      }
    },
    consentTerm(open) {
      this.printTerms = open;
    },
    preRequisitesTerm(open, requisites) {
      this.preRequisitesDialog = open;
      this.preRequisitesContent = requisites
    },
    hasAddress(offer) {
      return offer.address.street && offer.address.number && offer.address.city && offer.address.postalCode
    },
    isJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }
  },
};
</script>